<template>
  <b-modal
    v-model="modalDepreciableAssets"
    :title="
      editable
        ? 'Editar item'
        : modalDepreciableAssetsForm
        ? 'Cadastrar item'
        : 'Excluir item'
    "
    class="text-center text-primary d-flex align-items-center"
    no-close-on-backdrop
    hide-footer
    hide-header-close
  >
    <validation-observer ref="ModalDepreciableAssets">
      <b-form v-if="modalDepreciableAssetsForm">
        <b-row
          class="my-1 m-2 text-left mt-2 d-flex justify-content-center flex-column"
        >
          <b-row class="d-flex justify-content-sm-around pb-1">
            <!-- Item -->
            <b-col>
              <b-form-group class="mb-2">
                <label for="title" class="font-weight-bold text-primary"
                  >Item :</label
                >
                <validation-provider
                  #default="{ errors }"
                  :custom-messages="{ required: 'Este campo é obrigatório' }"
                  rules="required"
                >
                  <b-form-input
                    id="title"
                    v-model="modelDepreciableAssets.item_name"
                    placeholder="Digite o nome do item"
                  ></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- acquisition date -->
            <b-col>
              <b-form-group>
                <label for="date" class="font-weight-bold text-primary"
                  >Data da aquisição :</label
                >
                <validation-provider
                  #default="{ errors }"
                  :custom-messages="{ required: 'Este campo é obrigatório' }"
                  rules="required"
                >
                  <!-- <b-input-group append="%"> -->
                  <b-form-input
                    id="date"
                    type="date"
                    v-model="modelDepreciableAssets.acquisition_date"
                  ></b-form-input>
                  <!-- </b-input-group> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="d-flex justify-content-sm-around pb-1">
            <!-- acquisition value -->
            <b-col>
              <b-form-group>
                <label for="acquisition" class="font-weight-bold text-primary"
                  >Valor de aquisição :</label
                >
                <validation-provider
                  #default="{ errors }"
                  :custom-messages="{ required: 'Este campo é obrigatório' }"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="acquisition"
                      type="text"
                      v-money="moneyMask"
                      v-model="modelDepreciableAssets.acquisition_value"
                    ></b-form-input>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- <b-col>
              <b-form-group>
                <label for="residual" class="font-weight-bold text-primary"
                  >Valor residual :</label
                >
                <validation-provider
                  #default="{ errors }"
                  :custom-messages="{ required: 'Este campo é obrigatório' }"
                  rules="required"
                >
                  <b-input-group append="R$">
                    <b-form-input
                      id="residual"
                      type="number"
                      v-model="modelDepreciableAssets.residual_value"
                    ></b-form-input>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <!-- useful life -->
            <b-col>
              <b-form-group>
                <label for="life" class="font-weight-bold text-primary"
                  >Vida útil :</label
                >
                <validation-provider
                  #default="{ errors }"
                  :custom-messages="{ required: 'Este campo é obrigatório' }"
                  rules="required"
                >
                  <b-input-group prepend="Meses">
                    <b-form-input
                      id="life"
                      type="number"
                      v-model="modelDepreciableAssets.lifespan_months"
                    ></b-form-input>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Depreciable -->
          <b-row
            class="my-1 m-2 mb-2 d-flex justify-content-md-between text-center"
          >
            <!-- management and tax -->
            <b-col
              col
              xs="12"
              sm="12"
              md="6"
              lg="6"
              class="justify-content-center d-flex"
            >
              <b-form-group v-slot="{ ariaDescribedby }">
                <label
                  for="contact"
                  class="text-center font-weight-bold text-primary pb-1"
                >
                  Escolha uma opção:
                </label>
                <b-row>
                  <b-form-radio
                    class="mr-2"
                    v-model="modelDepreciableAssets.depreciation"
                    :aria-describedby="ariaDescribedby"
                    name="selected-prefix-management"
                    value="gerencial"
                  >
                    Gerencial
                  </b-form-radio>
                  <b-form-radio
                    v-model="modelDepreciableAssets.depreciation"
                    :aria-describedby="ariaDescribedby"
                    name="selected-prefix-tax"
                    value="fiscal"
                    @change="updateResidualValueStatus"
                  >
                    Fiscal
                  </b-form-radio>
                </b-row>
              </b-form-group>
            </b-col>

            <!-- residual value -->
            <b-col>
              <b-form-group>
                <label for="residual" class="font-weight-bold text-primary">
                  Valor residual :
                </label>
                <validation-provider
                  #default="{ errors }"
                  :custom-messages="{ required: 'Este campo é obrigatório' }"
                  rules="required"
                >
                  <b-input-group>
                    <b-form-input
                      id="residual"
                      type="text"
                      v-model="modelDepreciableAssets.residual_value"
                      v-money="moneyMask"
                      :disabled="
                        modelDepreciableAssets.depreciation == 'fiscal'
                      "
                    ></b-form-input>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-row>

        <hr class="invoice-spacing mb-3" />

        <b-row class="justify-content-between d-flex p-2">
          <!-- back -->
          <b-button
            :disabled="loading"
            variant="warning"
            class="mr-2"
            @click="closeModal()"
            >Voltar
          </b-button>
          <!-- inactivate -->
          <b-button
            v-if="editable ? modelDepreciableAssets : modalInactivate.disabled"
            :variant="modelDepreciableAssets.is_blocked ? 'success' : 'danger'"
            class="d-flex justify-content-center mr-2"
            @click="modalInactivate = true"
            :disabled="modelDepreciableAssets.item_name.length < 1 || loading"
          >
            <span
              v-if="!loading"
              id="modal-inactivate"
              @click="
                modelDepreciableAssets.is_blocked
                  ? updateDepreciableAssets
                  : (modalInactivate = true)
              "
              >{{
                modelDepreciableAssets.is_blocked ? 'Reativar' : 'Inativar'
              }}</span
            >
            <b-spinner v-if="loading"></b-spinner>
          </b-button>

          <!-- updateDepreciableAssets -->

          <div class="d-flex justify-content-between">
            <b-button
              v-if="editable && !modelDepreciableAssets.is_blocked"
              variant="primary"
              @click="updateDepreciableAssets"
              :disabled="modelDepreciableAssets.item_name.length < 1 || loading"
            >
              <span v-if="!loading"> Salvar Alterações </span>
              <b-spinner class="spinner" v-if="loading"></b-spinner>
            </b-button>

            <!-- saveDepreciableAssets -->

            <b-button
              v-if="!editable"
              variant="primary"
              @click="saveDepreciableAssets"
              :disabled="modelDepreciableAssets.item_name.length < 1 || loading"
            >
              <span v-if="!loading">Salvar</span>
              <b-spinner v-if="loading"></b-spinner>
            </b-button>
          </div>

          <!-- modalInativate -->
          <b-modal
            title="Inativar item"
            no-close-on-backdrop
            hide-footer
            hide-header-close
            v-model="modalInactivate"
          >
            <b-row class="mt-1 justify-content-center d-flex text-center">
              <p>
                Deseja realmente
                <strong>{{
                  modelDepreciableAssets.is_blocked ? 'reativar' : 'inativar'
                }}</strong>
                este item <strong>{{ modelDepreciableAssets.item_name }}</strong
                >?
              </p>
            </b-row>
            <hr class="invoice-spacing mb-3" />
            <b-row class="justify-content-between d-flex mt-3 p-1">
              <b-button variant="warning" @click="modalInactivate = false">
                Cancelar
              </b-button>
              <b-button
                variant="primary"
                @click="
                  modelDepreciableAssets.is_blocked
                    ? activeDepreciableAssets()
                    : removeDepreciableAssets()
                "
              >
                Confirmar
              </b-button>
            </b-row>
          </b-modal>
        </b-row>
      </b-form>

      <!-- modalOpenDelete -->
      <div v-else modalOpenDelete>
        <div>
          <b-row class="mt-1 justify-content-center d-flex text-center">
            <p>
              Deseja realmente
              <strong>{{
                modelDepreciableAssets && 'Excluir este item '
              }}</strong>
              <strong>{{ modelDepreciableAssets.item_name }}</strong
              >?
            </p>
          </b-row>
          <hr class="invoice-spacing mb-3" />
          <b-row class="justify-content-between d-flex mt-3 p-1">
            <b-button variant="warning" @click="$emit('closeModal')">
              Cancelar
            </b-button>
            <b-button variant="primary" @click="removeDepreciableAssets()">
              Confirmar
            </b-button>
          </b-row>
        </div>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormRadio,
  BButton,
  BSpinner,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
/*
  Quando uma instância Vue é criada, ela adiciona todas as propriedades encontradas no objeto data ao sistema de reatividade do Vue. Quando os valores de qualquer destas propriedades muda, a camada visual “reage”, atualizando-se para condizer com os novos valores. A única exceção é ao usar Object.
*/
export default {
  name: 'new-depreciableAssets',
  props: {
    editable: {
      Type: Boolean,
      default: false,
    },
    modalDepreciableAssets: {
      Type: Boolean,
      default: false,
    },
    modalDepreciableAssetsForm: {
      Type: Boolean,
      default: false,
    },
    ModalOpenDeleteDepreciableAssets: {
      Type: Boolean,
      default: false,
    },
    itemUpdate: {
      type: Object && String && Number,
      default: null,
    },
    asset_id: {
      Type: String,
      default: '',
    },
  },

  components: {
    BModal,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormRadio,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },

  // Variables
  data() {
    return {
      moneyMask: {
        prefix: 'R$ ',
        suffix: '',
        thousands: '.',
        decimal: ',',
        precision: 2,
        masked: false,
      },
      modelDepreciableAssets: {
        item_name: '',
        acquisition_date: '',
        acquisition_value: '',
        residual_value: '',
        lifespan_months: '',
        depreciation: 'gerencial',
        is_blocked: false,
        workspace_id: null,
      },
      modelDepreciableAssetsDefault: {
        item_name: '',
        acquisition_date: '',
        acquisition_value: '',
        residual_value: '',
        lifespan_months: '',
        depreciation: '',
        is_blocked: false,
        workspace_id: null,
      },
      modalInactivate: false,
      loading: false,
      required,
    };
  },
  watch: {
    editable(itemUpdate) {
      if (itemUpdate == true) {
        this.getDepreciableAssets();
      }
    },
  },

  computed: {
    isResidualValueDisabled() {
      return this.modelDepreciableAssets.disabled === 'true';
    },
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },
  before() {
    if (this.editable) {
      this.getDepreciableAssets();
    }
  },


  methods: {
    modalOpenDelete() {
      this.ModalOpenDeleteDepreciableAssets = true;
    },

    closeModal() {
      this.modelDepreciableAssets = this.modelDepreciableAssetsDefault;
      this.$emit('closeModal');
    },

    updateResidualValueStatus() {
      this.$nextTick(() => {
        this.modelDepreciableAssets.disabled =
          this.modelDepreciableAssets.depreciation === 'gerencial';
      });
    },

    // saveDepreciableAssets
    saveDepreciableAssets() {
      this.$refs.ModalDepreciableAssets.validate().then((success) => {
        if (success) {
          this.modelDepreciableAssets.workspace_id = this.currentWorkspace.id;
          this.loading = true;
          this.$store
            .dispatch('saveDepreciableAssets', {
              ...this.modelDepreciableAssets,
              residual_value:
                this.modelDepreciableAssets.depreciation == 'fiscal'
                  ? 0
                  : this.monetaryToNumber(
                      this.modelDepreciableAssets.residual_value
                    ),
              acquisition_value: this.monetaryToNumber(
                this.modelDepreciableAssets.acquisition_value
              ),
            })
            .then((resp) => {
              this.loading = false;

              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Item cadastrado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$emit('closeModal');
                this.$emit('updateList');
                this.modelDepreciableAssets =
                  this.modelDepreciableAssetsDefault;
              }
            })

            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao cadastrar item',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    // getDepreciableAssets
    getDepreciableAssets() {
      this.loading = true;

      let asset_id = this.itemUpdate;

      this.$store
        .dispatch('getDepreciableAssets', { id: asset_id })
        .then((resp) => {
          this.loading = false;

          this.modelDepreciableAssets = {
            ...resp,
            residual_value: this.numberToMonetary(resp.residual_value),
            acquisition_value: this.numberToMonetary(resp.acquisition_value),
            acquisition_date: this.dateFormatter(
              resp.acquisition_date,
              'yyyy-MM-dd'
            ),
            is_blocked: resp.deleted_at ? true : false,
          };
          console.log(this.numberToMonetary(resp.residual_value) )
        })
        .catch(() => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao buscar item',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    // updateDepreciableAssets
    updateDepreciableAssets() {
      this.$refs.ModalDepreciableAssets.validate().then((success) => {
        if (success) {
          this.loading = true;

          const newParams = {
            id: this.modelDepreciableAssets.id,
            lifespan_months: this.modelDepreciableAssets.lifespan_months,
            item_name: this.modelDepreciableAssets.item_name,
            depreciation: this.modelDepreciableAssets.depreciation,
            residual_value: this.monetaryToNumber(
              this.modelDepreciableAssets.residual_value
            ),
            acquisition_value: this.monetaryToNumber(
              this.modelDepreciableAssets.acquisition_value
            ),
          };

          this.$store
            .dispatch('updateDepreciableAssets', newParams)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$emit('renderList');
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Item atualizado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$emit('closeModal');
                this.modelDepreciableAssets =
                  this.modelDepreciableAssetsDefault;
              }
            })
            .catch(() => {
              this.loading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'erro ao atualizar o item',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    // inactivateDepreciableAssets
    inactivateDepreciableAssets() {
      this.loading = true;
      this.$store
        .dispatch('inactivateDepreciableAssets', this.modalDepreciableAssets)
        .then((resp) => {
          this.loading = false;
          if (resp) {
            this.modalInactivate = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Item inativada com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.$router.push({ name: 'depreciableAssets' });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'erro ao inativa item',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    // activeDepreciableAssets
    activeDepreciableAssets() {
      this.$refs.ModalDepreciableAssets.validate().then((success) => {
        if (success) {
          this.loading = true;
          // const model = { ...this.modelDepreciableAssets, deleted_at: null };
          this.$store
            .dispatch('activeDepreciableAssets', this.modelDepreciableAssets)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Item reativado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$emit('closeModal');
                this.$router.push({ name: 'depreciableAssets' });
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'erro ao reativar Item',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    // removeDepreciableAssets
    removeDepreciableAssets() {
      this.$refs.ModalDepreciableAssets.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('removeDepreciableAssets', this.itemUpdate)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$emit('renderList');
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Item removido com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$emit('closeModal');
                this.modelDepreciableAssets =
                  this.modelDepreciableAssetsDefault;
              }
            })
            .catch((err) => {
              console.log({ err });
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao deletar item',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
          this.$emit('closeModal');
          this.modelDepreciableAssets = this.modelDepreciableAssetsDefault;
        }
      });
    },
  },
};
</script>
